import React, { Fragment, useState, useEffect } from "react";
import { Dropdown, Modal, Nav, Tab } from 'react-bootstrap';
import { getURLVariable } from '../helpers/UrlPGetters'
import { shoppersList, getProduct, filterShops } from "../../services/ProductService";
import SwiperCTN from "../layouts/SwiperCTN"
import tabData from "./subtabs/tabDataMain.json"
import BootstrapPagination from "../components/pagination/Paginations";

const Shoppers = () => {
   const [loading, setLoading] = useState(true)
   const [product, setProduct] = useState(null);
   const [toggle, setToggle] = useState(false);
   const [shoppers, setShoppers] = useState([]);
   const [, setParametro] = useState(null);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalItems, setTotalItems] = useState(null);

   const [productNameSearch, setProductNameSearch] = useState('');
   const [onSearch, setOnSearch] = useState(false);
   const [lastSearch, setLastSearch] = useState('');
   const [searchSteps, setSearchSteps] = useState('name');
   const [modalSearch, setModalSearch] = useState(false);
   const [searchValueType, setValueType] = useState('name');
   const [isLargeScreen,] = useState(window.innerWidth > 700);

   useEffect(() => {
      const param = getURLVariable('p');
      if (param !== null) {
         setParametro(param)
         const fetchProduct = async () => {
            try {
               const response = await getProduct(param);
               if (response.status === 200) {
                  setProduct(response.data.result)
               } else {

               }
            } catch (error) {
               console.error('Erro ao obter os produtos:', error);
            }
         };
         fetchProduct();
      }
   }, [product]);

   useEffect(() => {
      if (shoppers.length === 0 && !toggle) {
         const fetchShoppers = async () => {
            try {
               const response = await shoppersList(1, 20);
               if (response.status === 200) {
                  setShoppers(response.data.result);
                  setTotalItems(response.data.totalPage)
                  setCurrentPage(response.data.page)
                  setLoading(false)
                  setToggle(true);
               } else {
                  console.error('Erro ao obter os produtos:', response.statusText);
                  setToggle(true);
                  setLoading(false)
               }
            } catch (error) {
               console.error('Erro ao obter os produtos:', error);
               setToggle(true);
               setLoading(false)
            }
         };
         fetchShoppers();
      }
   }, [shoppers]);

   const handlePageChange = (page) => {
      if (!onSearch) {
         const fetchShoppers = async () => {
            try {
               const response = await shoppersList(page, 20);
               if (response.status === 200) {
                  setShoppers(response.data.result);
                  setTotalItems(response.data.totalPage)
                  setCurrentPage(page)
               } else {

               }
            } catch (error) {

            }
         };
         fetchShoppers();
      } else {
         const fetchShoppers = async () => {
            try {
               const response = await filterShops(productNameSearch, searchValueType, currentPage, 20);
               if (response.status === 200) {
                  setShoppers(response.data.result);
                  setTotalItems(response.data.totalPage)
                  setCurrentPage(page)
               } else {

               }
            } catch (error) {

            }
         };
         fetchShoppers();
      }
   };

   const handleStoreFilter = (searchValueType, value) => {

      const storeFilter = async () => {

         try {
            const response = await filterShops(value, searchValueType, currentPage, 20);
            if (response.status === 200) {
               setShoppers(response.data.result);
               setOnSearch(true);
               setLastSearch(value);
               setModalSearch(false);
            }
         } catch (error) {
            console.error("Erro ao filtrar lojas:", error);
         }
      };
      storeFilter();
   }

   const drop = (
      <Dropdown>
         <Dropdown.Toggle variant="" className="btn btn-primary tp-btn-light sharp i-false">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
               <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
               </g>
            </svg>
         </Dropdown.Toggle>
         <Dropdown.Menu>
            <Dropdown.Item href="#">Edit</Dropdown.Item>
            <Dropdown.Item href="#" className="text-danger">
               Delete
            </Dropdown.Item>
         </Dropdown.Menu>
      </Dropdown>
   );

   const lojaId = localStorage.cart ? JSON.parse(localStorage.cart) : { cart: {} }

   return (
      <>
         <div className="custom-tab-1 pb-3">
            <Tab.Container defaultActiveKey={tabData[1].name.toLowerCase()}>
               <Nav as="ul" className="navigatore">
                  {tabData.map((data, i) => (
                     <Nav.Item as="li" key={i}>
                        <Nav.Link eventKey={data.name.toLowerCase()} href={data.link}>
                           <i className={`la la-${data.icon} me-2`} />
                           {data.name}
                        </Nav.Link>
                     </Nav.Item>
                  ))}
                  {lojaId.cart.loja && lojaId.cart.loja.id ? (
                     <Nav.Item as="li">
                        <Nav.Link style={{ marginLeft: 6, backgroundColor: '#f54749', color: 'white', borderRadius: 4 }} eventKey={"Loja Ativa".toLowerCase()} href={`/loja?p=${lojaId.cart.loja.id}`}>
                           <i className={`bi bi-shop me-2`} style={{ color: 'white' }} />
                           Loja Ativa
                        </Nav.Link>
                     </Nav.Item>
                  ) : null}
               </Nav>
            </Tab.Container>
         </div>
         <Fragment>
            <div className="row">
               <div className="col-md-12 pt-3">
                  {
                     product !== null ?
                        <span style={{ display: 'flex', gap: 12, display: 'flex', justifyContent: 'center' }}>
                           <span
                              style={{
                                 width: 250,
                                 display: 'block',
                                 padding: 7,
                                 backgroundColor: 'white',
                                 border: '3px solid #f1f1f1',
                                 borderRadius: 5,
                                 position: 'relative',
                              }}
                           >
                              onde encontrar: <b>{product.name}</b>
                              <span style={{
                                 display: 'flex',
                                 justifyContent: 'center',
                                 alignItems: 'center'
                              }}>
                                 <span>
                                    <br />
                                    {
                                       product.images.length > 0 ?
                                          <img className="" style={{ width: '100%' }} src={'https://core.pskeromais.com.br/' + product.images[0].url} />
                                          :
                                          <span style={{
                                             width: '100%',
                                             height: 200,
                                             backgroundImage: "gray",
                                             margin: '0 auto',
                                             display: 'flex',
                                             alignItems: 'center',
                                             justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                                          }}>
                                             <i className='bi bi-gift' style={{ fontSize: 45, color: 'white' }} />
                                          </span>
                                    }
                                 </span>
                              </span>
                              <i className="bi bi-caret-down-fill"
                                 style={
                                    {
                                       position: 'absolute',
                                       right: 5,
                                       bottom: -20,
                                       fontSize: 20,
                                       color: '#f1f1f1'
                                    }
                                 }></i>
                           </span>
                           <span style={{ borderLeft: '1px #f1f1f1 solid', paddingLeft: 10 }}>
                              encontrar outro produto?
                              <br />
                              <button className="btn btn-outline-dark mt-2">
                                 <i className="bi bi-search"></i> nova busca
                              </button>
                           </span>
                        </span>
                        : null
                  }
                  <div>
                     {!onSearch ?
                        <span style={{ padding: '5px 0 20px 0', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center', gap: 5, width: '100%' }}>
                           <button className="btn btn-primary-outline border-primary text-primary" onClick={() => setModalSearch(true)}>
                              procurar loja
                           </button>
                        </span>
                        : onSearch ?
                           <div style={{ padding: '5px 0 20px 0', display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'center' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                 <label><i className="fa-solid fa-magnifying-glass"></i> buscando por:</label>
                                 <h4 style={{ backgroundColor: 'rgb(241, 241, 241)', padding: "6px 17px" }}><b className="text-primary">{lastSearch}</b></h4>
                              </div>
                              <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center', gap: 5 }}>
                                 <button className="btn btn-primary-outline border-primary text-primary" onClick={() => setModalSearch(true)}>
                                    procurar nova loja
                                 </button>
                              </span>
                           </div>
                           : null
                     }

                     <ul className="btn-reveal-trigger row">
                        {shoppers.length > 0 ?
                        isLargeScreen?
                           shoppers.map((Data, index) => (
                              <li className="col-sm-6 col-md-4"
                                 style={{
                                    marginBottom: 6,
                                    borderRadius: 6,
                                 }} key={index}>
                                 <div
                                    className="p-1"
                                    style={{
                                       boxShadow: '2px 2px 2px #f1f1f1',
                                    }}>
                                    {
                                       Data.images.length === 0 ? (
                                          <span style={{
                                             width: '100%',
                                             height: 120,
                                             backgroundImage: "gray",
                                             margin: '0 auto',
                                             display: 'flex',
                                             alignItems: 'center',
                                             justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                                          }}>
                                             <i className='bi bi-shop' style={{ fontSize: 45, color: 'white' }} />
                                          </span>)
                                          : <img className="" style={{ width: '100%' }} src={'https://core.pskeromais.com.br/' + Data.images[0].url} />
                                    }
                                    <span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                       <div className="media d-flex align-items-center">
                                          <div className="media-body">
                                             <br />
                                             <h5 className="mb-0 fs--1">
                                                {Data.name}
                                             </h5>
                                          </div>
                                       </div>
                                       <span style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', gap: 5 }}>
                                          <span className="d-flex flex-column" style={{ padding: 0, wordBreak: 'break-all' }}>
                                             <span style={{ color: '#f54749' }}>
                                                <i className="bi bi-person-badge"></i> Email
                                             </span>
                                             <span style={{ color: '#f54749' }}></span> <div>{Data.taxId}</div>
                                          </span>
                                          {/* <span className="d-flex flex-column" style={{ padding: 0, wordBreak: 'break-all' }}>
                                          <span style={{ color: '#f54749' }}>
                                             <i className="bi bi-envelope"></i> Email
                                          </span>
                                          <div>{Data.email}</div>
                                       </span> */}
                                          <span className="d-flex flex-column" style={{ padding: 0, wordBreak: 'break-all' }}>
                                             <span style={{ color: '#f54749' }}>
                                                <i className="bi bi-telephone"></i> Telefone
                                             </span>
                                             <div>{Data.phone}</div>
                                          </span>
                                          <span className="d-flex flex-column" style={{ padding: 0, wordBreak: 'break-all' }}>
                                             <span style={{ color: '#f54749' }}>
                                                <i className="fa-solid fa-location-dot text-primary"></i> Endereço
                                             </span>
                                             {
                                                Data.addresses[0].street +
                                                ' ( ' + Data.addresses[0].zipCode + ' ) '
                                             }
                                             {Data.addresses[0].number ? ', Nº ' + Data.addresses[0].number : 'S/N'}
                                             {
                                                '' +
                                                ' , ' +
                                                Data.addresses[0].city +
                                                ' - ' + Data.addresses[0].state
                                             }
                                          </span>
                                       </span>
                                       {
                                          product ?
                                             <span>
                                                <label className="small m-0" style={{ color: '#c6c6c6' }}><i className="bi bi-tags"></i> oferta </label><br />
                                                <span style={{ display: 'block', width: 132, color: "#5f8200", backgroundImage: 'linear-gradient(165deg, rgb(214 255 101), rgb(165 208 47))', padding: 7, borderRadius: 7 }}>
                                                   <br />
                                                   <p style={{ fontSize: 25, margin: 0, lineHeight: 1.2 }}>R$ <span style={{ fontWeight: 'bold' }}>1.14</span></p>
                                                   <i className="" style={{ position: 'absolute', right: 3, top: 3, fontSize: 20 }} />
                                                </span>
                                             </span>
                                             : null
                                       }
                                       <a href={`/loja?p=${Data.id}`} className="btn btn-primary py-1 px-3 mt-2">
                                          ver loja
                                       </a>
                                    </span>
                                 </div>
                                 <br />
                              </li>
                           ))
                        : 
                        <>

                           <SwiperCTN products={shoppers.slice( 0 , 4)} />
                           <SwiperCTN products={shoppers.slice( 5 , 9)} />
                           <SwiperCTN products={shoppers.slice( 10 , 14)} />
                           <SwiperCTN products={shoppers.slice( 15 , 19)} />
                        </>
                        : null}
                     </ul>
                     {shoppers.length > 0 ?
                        <BootstrapPagination
                           totalItems={totalItems}
                           currentPage={currentPage}
                           onPageChange={handlePageChange}
                        />
                        : null}


                  </div>
               </div>
            </div>
         </Fragment>
         <Modal show={modalSearch}>
            <button onClick={() => setModalSearch(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
               <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
            </button>
            <span
               style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  justifyContent: 'spaceBetween',
                  width: '100%',
                  margin: '10px 0',
                  justifyContent: 'space-around',
                  justifyContent: 'center',
                  alignContent: 'center'
               }}
            >
               <span
                  style={{
                     display: 'flex',
                     flexWrap: 'wrap',
                     flexDirection: 'column',
                     justifyContent: 'spaceBetween',
                     justifyContent: 'space-around',
                     padding: '14px',
                     justifyContent: 'center',
                     gap: '10px',
                     border: '1px #f6f6f6 solid'
                  }}
               >
                  <span style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                     <h5 className="" style={{ margin: 0 }}><i className="fa-solid fa-magnifying-glass"></i></h5>
                     <h5 className="text-primary" style={{ margin: 0 }}>Pesquisar Loja</h5>
                  </span>
                  <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                     {searchSteps === 'name' ?
                        <span style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 5 }}>
                           <input onChange={(e) => setProductNameSearch(e.target.value)} placeholder="pesquisar por nome" style={{ width: '100%', padding: '2px 5px', textAlign: 'center', height: 40 }} />
                           <button className={`btn btn-light`} style={{ fontSize: 12 }} onClick={() => handleStoreFilter(searchValueType, productNameSearch)}>buscar</button>
                        </span>
                        : searchSteps === 'address' ?
                           <span style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 5 }}>
                              <span style={{ display: 'flex', flexDirection: 'row' }}>
                                 <input placeholder="pesquisar por endereço" style={{ width: '100%', padding: '2px 5px', textAlign: 'center', height: 40 }} />
                                 <select style={{ width: '120px', textAlign: 'center', height: 40, color: 'gray' }}>
                                    <option onChange={(e) => { setValueType('street') }} >rua</option>
                                    {/* <option>número</option> */}
                                    <option onChange={(e) => setValueType('neighborhood')}>bairro</option>
                                    <option onChange={(e) => setValueType('city')}>cidade</option>
                                 </select>
                              </span>
                              <button className={`btn btn-light`} style={{ fontSize: 12 }} onClick={() => handleStoreFilter(searchValueType, productNameSearch)}>buscar</button>
                           </span>
                           : null}
                  </span>
                  <span style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', gap: 5 }}>
                     <button className={`btn ${searchSteps === 'name' ? 'btn-primary-outline text-primary' : 'btn-primary text-light'} border border-primary`} style={{ fontSize: 12 }} onClick={() => setSearchSteps('name')}>nome</button>
                     <button className={`btn ${searchSteps === 'address' ? 'btn-primary-outline text-primary' : 'btn-primary text-light'} border border-primary`} style={{ fontSize: 12 }} onClick={() => setSearchSteps('address')}>endereço</button>
                  </span>
               </span>
            </span>
         </Modal>
         <span style={{ zIndex: 9999, width: '100%', height: '100vh', backgroundColor: 'rgba(41 ,180,226,0.6)', position: 'absolute', top: 0, right: 0, display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }} >
            <i id="loading-arrow-in-circle" className="bi bi-arrow-clockwise" style={{ color: 'white', fontSize: 75 }}></i>
         </span>
      </>
   );
};

export default Shoppers;
