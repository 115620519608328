import React , { useEffect } from 'react';
import './SimplePagination.css'; // Para os estilos personalizados

const SimplePagination = ({ totalItems, currentPage, onPageChange }) => {
  const totalPages = totalItems;

  const handleClick = (page) => {
    console.log(page)
    onPageChange(page);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`page-item ${i === currentPage ? 'active' : ''}`}
          onClick={() => handleClick(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="d-flex flex-column">
      <hr style={{width: '100%' , margin: '20px 0 5px'}}/>
      <div className="pagination" style={{flexWrap: 'wrap'}}>
        <button onClick={handlePrev} disabled={currentPage === 1} className="page-item">
          <i className="bi bi-arrow-left-circle-fill" style={{fontSize: 20 , color: 'rgb(18, 176, 81)'}}></i>
        </button>
        {renderPageNumbers()}
        <button onClick={handleNext} disabled={currentPage === totalPages} className="page-item">
          <i className="bi bi-arrow-right-circle-fill" style={{fontSize: 20 , color: 'rgb(18, 176, 81)'}}></i>
        </button>
      </div>
    </div>
  );
};

export default SimplePagination;
