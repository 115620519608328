import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getProfile, createAddress } from '../../services/UserService';
import { createOrder } from '../../services/OrderService';
import { ShopperRequest } from '../../services/ProductService'

const paymentMethodsNIDs = [
    { id: '0c67aacd-4242-4cc1-a56c-24d642287f4b', method: 'CARTÃO DE DÉBITO' },
    { id: '244a92b6-8cc2-4c33-a6ea-37ad48dcb17e', method: 'CARTÃO DE CRÉDITO' },
    { id: '40270f4a-df53-46ab-8764-effb596f8daa', method: 'TRANSFERÊNCIA BANCÁRIA' },
    { id: '6dbf0c66-c794-4b61-888c-ad6d06161583', method: 'PIX' },
    { id: 'dbe187b9-a64c-4dca-92d0-2f2e140e0f17', method: 'DINHEIRO' },
    { id: 'ed061e9a-40cb-4ef8-9353-4c8c346c563d', method: 'BOLETO BANCÁRIO' },
]

const FoodOrder = () => {
    let letcartDummy = JSON.parse(localStorage.getItem('cart'))
    const cartDummy = letcartDummy?letcartDummy:{cart:{}}
    const valorTotalDummy = cartDummy.cart.itens? cartDummy.cart.itens.reduce((acc, item) => acc + (item.produto.salePrice * item.quantidade), 0):0
    const quantidadeTotalDummy = cartDummy.cart.itens? cartDummy.cart.itens.reduce((acc, item) => acc + item.quantidade, 0):0
    const [loading, setLoading] = useState(true);
    const [cart, setCart] = useState(cartDummy);
    const [shopper, setShopper] = useState(null);
    const [partner, setPartner] = useState(null);
    const [modalAddress, setModalAddress] = useState(false)
    const [modalNewAddress, setModalNewAddress] = useState(false)
    const [modalPayment, setModalPayment] = useState(false)
    const [modalSendOrder, setModalSendOrder] = useState(false)
    const [modalSendOrderDone, setModalSendOrderDone] = useState(false)
    const [deliveryAddressId, setDeliveryAddressId] = useState('-')
    const [deliveryAddresses, setDeliveryAddresses] = useState([])
    const [paymentId, setPaymentId] = useState('-')
    const [itens , setItens] = useState(cartDummy.cart.itens)
    const [valorTotal] = useState(valorTotalDummy)
    const [quantidadeTotal] = useState(quantidadeTotalDummy)
    const [orderDiscount] = useState(0.5)
    const [orderID , setOrderID] = useState('')

    const checkProfile = async () => {
        try {
            const fetchProfile = async () => {
                try {
                    const response = await getProfile();
                    if (response.status === 200) {
                        setShopper(response.data)
                        setDeliveryAddresses(response.data.addresses)
                    } else {
                        console.error('Erro ao checar profile:', response.statusText);
                    }
                } catch (error) {
                    console.error('Erro ao checar profile:', error);
                }
            };
            fetchProfile();
        } catch (error) {
            console.error('Erro ao obter o valor de "p" da URL:', error);
        }
    };

    useEffect(() => {
        if (partner === null) {
            const checkPartner = async () => {
                try {
                    const fetchPartner = async () => {
                        try {
                            const response = await ShopperRequest(cart.cart.loja.id);
                            if (response.status === 200) {
                                setPartner(response.data.result)
                                setLoading(false)
                            } else {
                                console.error('Erro ao obter os produtos:', response.statusText);
                                setLoading(false)
                            }
                        } catch (error) {
                            console.error('Erro ao obter os produtos:', error);
                            setLoading(false)
                        }
                    };
                    fetchPartner();
                } catch (error) {
                    console.error('Erro ao obter o valor de "p" da URL:', error);
                    setLoading(false)
                }
            };
            checkPartner();
        }
    }, [partner]);

    const createNewAddress = async (Street, Number, Neighborhood, City, State, ZipCode) => {
        try {
            const fetchProfile = async () => {
                try {
                    const response = await createAddress(Street, Number, Neighborhood, City, State, ZipCode);
                    if (response.status === 200) {
                        setShopper({})
                    } else {
                        console.error('Erro ao criar endereço:', response.statusText);
                    }
                } catch (error) {
                    console.error('Erro ao criar endereço:', error);
                }
            };
            fetchProfile();
        } catch (error) {
            console.error('Erro ao obter o valor de "p" da URL:', error);
        }
    };

    const handleAddressSubmit = (newAddress) => {
        createNewAddress(newAddress.Street, newAddress.Number, newAddress.Neighborhood, newAddress.City, newAddress.State, newAddress.ZipCode)
    };

    const handleCancelNewAddress = ( ) => {
        setModalNewAddress(false)
    }

    const mapOrderItems = (itensD) => {
        return itensD.map(item => ({
            quantity: item.quantidade,
            productId: item.produto.id
        }));
    };

    const sendOrder = async (CustomerId, ShippingAddressId , StoreId , OrderItems , PaymentMethodId , DeliveryRate , Discount) => {
        let dumbItens = mapOrderItems(OrderItems)
        try {
            const fetchProfile = async () => {
                try {
                    const response = await createOrder(CustomerId, ShippingAddressId , StoreId , dumbItens , PaymentMethodId , DeliveryRate , Discount)
                    if (response.status === 200) {
                        setModalSendOrder(false)
                        setModalSendOrderDone(true)
                        setItens([])
                        setOrderID(response.data.message)
                        const cartDummy = JSON.stringify({cart: {
                            loja: {},
                            status: "pendente",
                            itens: [], 
                            paymentmethodid: PaymentMethodId
                        }});    
                        localStorage.setItem('cart', cartDummy)
                    } else {
                        console.error('Erro ao criar endereço:', response.statusText);
                    }
                } catch (error) {
                    console.error('Erro ao criar endereço:', error);
                }
            };
            fetchProfile();
        } catch (error) {
            console.error('Erro ao obter o valor de "p" da URL:', error);
        }
    };

    const confirmPaymentMethod = () => {
        cart.cart.paymentmethodid = paymentId;
        localStorage.setItem('cart', JSON.stringify(cart));
        setModalPayment(false)
    };

    useEffect(() => {
        if (!shopper) {
            checkProfile();
        }
    }, [shopper]);

    // useEffect(() => {
    //     console.log('modais finalização e pagamento')
    //     console.log(modalPayment)
    //     console.log('------------------------------')
    //     console.log(modalSendOrder)
    // }, [modalPayment , modalSendOrder]);

    return (
        <>
            <div className="row" style={{paddingTop: 30}}>
                {
                    valorTotalDummy !== 0?
                        <div className="col-xl-12" style={{maxWidth: '400px', margin: '0 auto'}}>
                            <br />
                            <span className='d-flex flex-wrap justify-content-center gap-3'>
                                <span>
                                    <span style={{ display: 'flex', margin: '0 auto' , width: 50, height: 50, borderRadius: '100%', backgroundColor: '#dedede', justifyContent: 'center', alignItems: 'center' }}>
                                        <i className='bi bi-shop' style={{ fontSize: '30px' }} />
                                    </span>
                                    <br />
                                </span>
                                <span>
                                    <h3 style={{ margin: 0 }}>{cart.cart.loja.name}</h3>
                                    <p style={{ margin: 0 }}><i className="bi bi-whatsapp" style={{ marginRight: 4, color: '#F54749' }}></i>{cart.cart.loja.phone}</p>
                                    <p style={{ margin: 0 }}><i className="bi bi-telephone" style={{ marginRight: 4, color: '#F54749' }}></i>{cart.cart.loja.email}</p>
                                </span>
                            </span>
                            <br />
                            <br />
                            <br />
                        </div>
                        : null
                }
                <div className="col-xl-4 col-xxl-12 sp30">
                    <div className="">
                        <div className="px-3">
                            {
                            valorTotalDummy !== 0?
                                <div className="row">
                                    <div className="col-12 col-sm-6 pt-3 pb-3">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 text-primary">
                                                <i className="fa-solid fa-location-dot"></i> Endereço da entrega
                                            </h5>
                                        </div>
                                        <div className="tracker-bx" style={{ padding: 10, boxShadow: '3px 3px 3px rgb(235 235 235)', borderRadius: 6 , minHeight: 130  , display: 'flex' , flexDirection: 'column' , justifyContent: 'space-around' }}>
                                            <span>
                                                {
                                                    valorTotalDummy !== 0?
                                                        deliveryAddressId === '-' && deliveryAddresses.length > 0?
                                                            <span style={{ fontWeight: 'semibold' , color: '#6c6c6c' }}>
                                                                {
                                                                    deliveryAddresses[0].street +
                                                                    ' ( ' + deliveryAddresses[0].zipCode + ' ) ' +
                                                                    ', Nº '}
                                                                {deliveryAddresses[0].number ? deliveryAddresses[0].number : 'S/N'}
                                                                {'' +
                                                                    ' , ' +
                                                                    deliveryAddresses[0].city +
                                                                    ' - ' + deliveryAddresses[0].state
                                                                }
                                                            </span>
                                                            :
                                                            <span style={{ fontWeight: 'semibold' }}>

                                                            </span>
                                                        : null
                                                }
                                            </span>
                                            <button onClick={() => setModalAddress(true)} className='btn btn-light'>
                                                <i className="fa-solid fa-repeat"></i> trocar endereço de entrega
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 pt-3 pb-3">
                                        <div className="d-flex align-items-center">
                                            <h5 className="mb-0 text-primary">
                                                <i className="fa-regular fa-credit-card"></i> Forma de pagamento
                                            </h5>
                                        </div>
                                        <div style={{ padding: 10, boxShadow: '3px 3px 3px rgb(235 235 235)', borderRadius: 6 , minHeight: 130 , display: 'flex' , flexDirection: 'column' , justifyContent: 'space-around' }}>
                                            <span>
                                                {
                                                    paymentId === '-'?
                                                        !cart.cart.paymentmethodid?
                                                            <span style={{ fontWeight: 'semibold' , color: '#6c6c6c' }}>
                                                                forma de pagamento não selecionada
                                                            </span>
                                                        :   <span style={{ fontWeight: 'semibold', color: '#6c6c6c' }}>
                                                                {(() => {
                                                                    const selectedPaymentMethod = paymentMethodsNIDs.find(method => method.id === cart.cart.paymentmethodid);
                                                                    return selectedPaymentMethod ? selectedPaymentMethod.method : 'Método de pagamento desconhecido';
                                                                })()}
                                                            </span>
                                                :   <span style={{ fontWeight: 'semibold', color: '#6c6c6c' }}>
                                                        {(() => {
                                                            const selectedPaymentMethod = paymentMethodsNIDs.find(method => method.id === paymentId);
                                                            return selectedPaymentMethod ? selectedPaymentMethod.method : 'Método de pagamento desconhecido';
                                                        })()}
                                                    </span>
                                                }
                                            </span>
                                            <button onClick={() => setModalPayment(true)} className='btn btn-light'>
                                                <i className="fa-solid fa-repeat"></i> trocar forma de pagamento
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            :null
                            }
                            <div className="order-menu style-3">
                                <h5 className="my-4 text-primary"><i className="fa-solid fa-bag-shopping"></i> Produtos</h5>
                                {
                                valorTotalDummy !== 0?
                                itens.map((item, ind) => (
                                    <div className="d-flex align-items-center justify-content-between flex-wrap mb-3 gap-3" style={{ boxShadow: '3px 3px 3px rgb(235 235 235)', padding: 10, borderRadius: 6 }} key={'itens-' + ind}>
                                        <div className='d-flex align-items-center gap-3'>
                                            {
                                                !item.produto.images ?
                                                    <span style={{
                                                        width: 50,
                                                        height: '6vh',
                                                        backgroundImage: "gray",
                                                        margin: '0 auto',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
                                                    }}>
                                                        <i className='bi bi-gift' style={{ fontSize: 45, color: 'white' }} />
                                                    </span>
                                                    :
                                                    <span style={{
                                                        width: 70,
                                                        height: 70,
                                                        margin: '0 auto',
                                                        justifyContent: 'center',
                                                        overflow: 'hidden',
                                                        display: 'flex',
                                                        borderRadius: 6,
                                                        border: '1px #dedede solid',
                                                        backgroundColor: 'white',
                                                    }}>
                                                        <img className="" style={{ border: '0px', height: '100%', width: 'auto', display: 'block', margin: '0 auto' }} src={'https://core.pskeromais.com.br/' + item.produto.images[0].url} />
                                                    </span>
                                            }
                                            <span style={{ display: 'flex', flexDirection: 'column' }}>
                                                <h4 className="font-w600 text-nowrap mb-0">{item.produto.name}</h4>
                                                <h5 className='text-primary' style={{ position: 'relative' }}>
                                                    x{item.quantidade}
                                                </h5>
                                            </span>
                                        </div>
                                        <div className="d-flex" style={{ flexDirection: 'row' , gap: 10 }}>
                                            <span style={{ display: 'flex', flexDirection: 'column' }}>
                                                <label style={{ margin: 0 }}>unit.</label>
                                                <span className="mb-0" style={{ backgroundColor: 'rgb(231 231 231)', padding: '6px 15px', borderRadius: 5 , fontWeight: 'bold' }}>{item.produto.salePrice.toFixed(2)} R$ <span style={{ width: 1, height: 30, position: 'absolute', right: '-8px', backgroundColor: 'rgb(231 231 231)' }} /></span>
                                            </span>
                                            <span style={{ display: 'flex', flexDirection: 'column' }}>
                                                <label style={{ margin: 0 }}>total</label>
                                                <span className="text-primary mb-0" style={{ backgroundColor: 'rgb(231 231 231)', padding: '6px 15px', borderRadius: 5 , fontWeight: 'bold'}}>{(item.quantidade * item.produto.salePrice).toFixed(2) + " R$"}</span>
                                            </span>
                                        </div>
                                    </div>
                                ))
                                :
                                <div className="d-flex align-items-center justify-content-between flex-wrap mb-3 gap-3" style={{ boxShadow: '3px 3px 3px rgb(235 235 235)', padding: 10, borderRadius: 6 , flexDirection: 'column' }}>
                                    
                                    <h2 style={{margin: 0}}><i className="bi bi-cart"></i></h2>
                                    <h4 style={{color: 'gray'}}>nenhum produto adicionado ao carrinho</h4>
                                    
                                </div>    
                            }
                            </div>
                            {
                                valorTotalDummy !== 0?
                                    <>
                                        <div className="d-flex mb-3 mt-3" style={{ maxWidth: 450, textAlign: 'center', margin: '30px auto', flexDirection: 'row', gap: 3 , justifyContent: 'center' }}>
                                            {/* <span style={{ padding: 6, backgroundColor: 'white', boxShadow: '3px 3px 3px rgb(235 235 235)', borderRadius: 6 }}>
                                                <h4 style={{ position: 'relative', color: 'rgb(115 153 9)' , margin: 0 }}>{orderDiscount.toFixed(2)} R$</h4>
                                                <span style={{ color: 'gray' }}>desconto</span>
                                            </span> */}
                                            <span style={{ padding: 6, backgroundColor: 'white', boxShadow: '3px 3px 3px rgb(235 235 235)', borderRadius: 6 }}>
                                                <h4 style={{ position: 'relative', color: 'rgb(115 153 9)' , margin: 0 }}>{partner?partner.deliveryTax.toFixed(2):''} R$</h4>
                                                <span style={{ color: 'gray' }}> taxa de entrega</span>
                                            </span>
                                            <span style={{ padding: 6, backgroundColor: 'white', boxShadow: '3px 3px 3px rgb(235 235 235)', borderRadius: 6 }}>
                                                <h4 style={{ position: 'relative', color: 'rgb(115 153 9)' , margin: 0 }}>{valorTotal.toFixed(2)} R$</h4>
                                                <span style={{ color: 'gray' }}>{quantidadeTotal} produtos</span>
                                            </span>
                                        </div>
                                        <div className="d-flex mb-3 mt-3" style={{ maxWidth: 450, textAlign: 'center', margin: '30px auto', flexDirection: 'row', gap: 3 , justifyContent: 'center' }}>
                                            <span style={{ padding: 6, backgroundColor: 'white', boxShadow: '3px 3px 3px rgb(235 235 235)', borderRadius: 6 }}>
                                                <span style={{ color: 'gray' }}>total</span>
                                                <h3 style={{ position: 'relative', color: 'rgb(115 153 9)' , margin: 0 }}>{partner?((valorTotal + partner.deliveryTax) - orderDiscount).toFixed(2):''} R$</h3>
                                            </span>
                                        </div>
                                    </>
                                :null
                            }
                            <br />
                            {
                                valorTotalDummy !== 0?
                                    <div className="d-flex gap-3 mt-3" style={{ maxWidth: 350, textAlign: 'center', margin: '30px auto', flexDirection: 'row', justifyContent: 'center' }}>
                                        <button onClick={() => setModalSendOrder(true)} className='btn btn-primary'>
                                            <i className="fa-solid fa-motorcycle"></i> enviar pedido
                                        </button>
                                    </div>
                                :null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={modalAddress} onHide={setModalAddress} centered>
                <button onClick={() => setModalAddress(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    <h4 style={{ borderBottom: '1px solid #f1f1f1', paddingBottom: 7 }}><i className="fa-solid fa-location-dot"></i> Trocar Endereço</h4>
                    <br />
                    <select className="form-select">
                        {deliveryAddresses.map((address, index) => (
                            <option key={'deliveryAddresses-' + index} value={address.id}>
                                {`${address.street} ( ${address.zipCode} ) , Nº ${address.number || 'S/N'}, ${address.city} - ${address.state}`}
                            </option>
                        ))}
                    </select>
                    <br />
                    <div className='d-flex align-items-center justify-content-center flex-row' style={{ gap: 4 }}>
                        <button onClick={() => setModalAddress(false)} className='btn btn-primary'>
                            confirmar
                        </button>
                        <button onClick={() => {
                            setModalAddress(false);
                            setModalNewAddress(true);
                        }} className='btn btn-dark' style={{ border: '1px gray solid' }}>
                            cadastrar novo endereço
                        </button>
                        <button onClick={() => {
                            setModalAddress(false) ; 
                            setDeliveryAddressId('-');
                        }} className='btn btn-light'>
                            cancelar
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={modalPayment} onHide={setModalPayment} centered>
                <button onClick={() => setModalPayment(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    <h4 style={{ borderBottom: '1px solid #f1f1f1', paddingBottom: 7 }}><i className="fa-regular fa-credit-card"></i> Trocar Forma de Pagamento</h4>
                    <br />
                    <select className="form-select"  onChange={(e) => setPaymentId(e.target.value)}>
                        {paymentMethodsNIDs.map((method) => (
                            <option key={'paymentMethodsNIDs-' + method.id} value={method.id} selected={method.id === paymentId || method.id === cart.cart.paymentmethodid?"selected":null}>
                                {method.method}
                            </option>
                        ))}
                    </select>
                    <br />
                    <div className='d-flex align-items-center justify-content-center flex-row' style={{ gap: 4 }}>
                        <button onClick={() => confirmPaymentMethod( )} className='btn btn-primary'>
                            confirmar
                        </button>
                        <button onClick={() => {
                            setModalPayment(false) ; 
                            setPaymentId('-');
                        }} className='btn btn-light'>
                            cancelar
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={modalNewAddress} onHide={setModalNewAddress} centered>
                <button onClick={() => setModalNewAddress(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    <h4 style={{ borderBottom: '1px solid #f1f1f1', paddingBottom: 7 }}><i className="fa-solid fa-location-dot"></i> Cadastrar Novo Endereço </h4>
                    <br />
                    <NewAddressForm onSubmit={handleAddressSubmit} onCancel={() => handleCancelNewAddress()} />
                </div>
            </Modal>
            <Modal show={modalSendOrder} onHide={setModalSendOrder} centered>
                <button onClick={() => setModalSendOrder(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                    <div style={{display: 'flex' , flexDirection: 'column' , gap: 5}}>
                        <h2 className='text-primary text-center'><i className="fa-solid fa-motorcycle"></i></h2>
                        <h4 style={{ borderBottom: '1px solid #f1f1f1', paddingBottom: 7 }}> Confirmar Pedido ?</h4>
                    </div>
                    {cart.cart.paymentmethodid?'':<h4 className='text-primary'>selecione um método de pagamento</h4>}
                    {cart.cart.paymentmethodid?'':<button className='btn btn-light' onClick={() => {setModalSendOrder(false) ; setModalPayment(true)}}>selecionar pagamento</button>}
                    <br />
                    <div style={{display: 'flex' , flexDirection: 'row' , gap: 5}}>
                        <button onClick={() => sendOrder(
                                                shopper.id , 
                                                shopper.addresses[0].id , 
                                                cart.cart.loja.id , 
                                                cart.cart.itens , 
                                                cart.cart.paymentmethodid , 
                                                partner.deliveryTax , 
                                                orderDiscount
                                            )} 
                                        className='btn btn-primary' disabled={cart.cart.paymentmethodid?false:true}>
                            confirmar pedido
                        </button>
                        <button className="btn btn-primary-outline border-primary text-primary" onClick={() => setModalSendOrder( )}>
                            cancelar
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={modalSendOrderDone} onHide={setModalSendOrderDone} centered>
                <button onClick={() => setModalSendOrderDone(false)} className='btn btn-light p-1' style={{ width: 30, height: 30, position: 'absolute', right: 6, top: 6, borderRadius: '100%' }}>
                    <i className="bi bi-x-circle" style={{ fontSize: 14 }}></i>
                </button>
                <div className='d-flex align-items-center justify-content-center flex-column p-4' style={{ minHeight: 300 }}>
                <div style={{display: 'flex' , flexDirection: 'column' , gap: 5}}>
                    <h2 className='text-primary text-center'><i className="fa-solid fa-motorcycle"></i></h2>
                        <h4 style={{ borderBottom: '1px solid #f1f1f1', paddingBottom: 7 }}> Confirmar Pedido ?</h4>
                    </div>
                    <br />
                    <a className='btn btn-primary' href={`/pedido?p=${orderID}`} >
                        acompanhar pedido
                    </a>
                </div>
            </Modal>
            <span style={{zIndex: 9999 , width: '100%' , height: '100vh' , backgroundColor: 'rgba(41 ,180,226,0.6)' , position: 'absolute' , top: 0, right: 0, display: loading?'flex':'none' , justifyContent: 'center' , alignItems: 'center'}} >
                <i id="loading-arrow-in-circle" class="bi bi-arrow-clockwise" style={{color: 'white' , fontSize: 75}}></i>
            </span>
        </>
    )
}
export default FoodOrder;

const NewAddressForm = ({ onSubmit, onCancel }) => {
    const [formData, setFormData] = useState({
        Street: '',
        Number: '',
        Neighborhood: '',
        City: '',
        State: '',
        ZipCode: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <form style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', gap: 5 }} onSubmit={handleSubmit}>
            <div>
                <label>Rua:</label>
                <input
                    type="text"
                    name="Street"
                    value={formData.Street}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <div>
                <label>Número:</label>
                <input
                    type="text"
                    name="Number"
                    value={formData.Number}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <div>
                <label>Bairro:</label>
                <input
                    type="text"
                    name="Neighborhood"
                    value={formData.Neighborhood}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <div>
                <label>Cidade:</label>
                <input
                    type="text"
                    name="City"
                    value={formData.City}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <div>
                <label>Estado:</label>
                <input
                    type="text"
                    name="State"
                    value={formData.State}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <div>
                <label>CEP:</label>
                <input
                    type="text"
                    name="ZipCode"
                    value={formData.ZipCode}
                    onChange={handleChange}
                    className="form-control"
                />
            </div>
            <br />
            <div style={{ display: 'flex', flexDirection: 'row', gap: 3, marginTop: 20 }}>
                <button className='btn btn-primary' type="submit">Salvar Novo Endereço</button>
                <button className='btn btn-light' type="button" onClick={() => onCancel()}>Cancelar</button>
            </div>
        </form>
    );
};