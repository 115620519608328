import React from 'react';
import { Swiper, SwiperSlide, } from "swiper/react";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";

import "swiper/css";

const SwiperCTN = (props) => {

	const setStock = (id) => {
		console.log('--- id ----')
		console.log(id)
		props.stock(id)
	}

	const setPrice = (id) => {
		console.log('--- id ----')
		console.log(id)
		props.price(id)
	}

	return (
		<>
			<Swiper className="mySwiper-2"
				speed={3200}
				slidesPerView={5}
				spaceBetween={20}
				autoplay={{
					delay: 1200,
				}}
				modules={[Autoplay]}
				breakpoints={{
					360: {
						slidesPerView: 2,
						spaceBetween: 20,
					},
					600: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					700: {
						slidesPerView: 4,
						spaceBetween: 20,
					}
					,
					1200: {
						slidesPerView: 3,
						spaceBetween: 20,
					},
					1920: {

						slidesPerView: 5,
						spaceBetween: 20,
					},
				}}
			>
				{//props.products > 0 ? 

					props.products.map((Data, key) => {

						return (
							<SwiperSlide key={key + '-swipper'}>
								<div className="cate-bx text-center">
									<div className="card">
										<div
											className="p-1"
											style={{
												boxShadow: '2px 2px 2px #f1f1f1',
											}}>
											<span style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
												{Data.images.length > 0 ?
													<img className="" style={{ width: '100%' }} src={'https://core.pskeromais.com.br/' + Data.images[0].url} />
													: <span style={{
														width: '100%',
														height: 120,
														backgroundImage: "gray",
														margin: '0 auto',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center', gap: 2, backgroundColor: 'rgb(241 241 241)'
													}}>
														<i className='bi bi-gift' style={{ fontSize: 45, color: 'white' }} />
													</span>
												}
												<div className="d-flex align-items-center" style={{ width: '100%', justifyContent: 'center' }}>
													<div>
														<br />
														<div className="d-flex" style={{ justifyContent: 'center', width: '100%' }}>
															<h4 style={{ textAlign: 'center', width: '100%', color: '#036383' }}>
																{Data.name}
															</h4>
														</div>
													</div>
												</div>

												<span style={{ display: 'flex',gap: 1 , flexDirection: 'column', alignItems: 'flex-start' , marginBottom: '10px', gap: 5 }}>
													<span className="d-flex flex-column" style={{ padding: 0, wordBreak: 'break-all' }}>
														<span style={{ color: '#f54749' , textAlign: 'left' }}>
															<i className="bi bi-person-badge"></i> Email
														</span>
														<span style={{ color: '#f54749' , textAlign: 'left' }}></span> <div>{Data.taxId}</div>
													</span>
													<span className="d-flex flex-column" style={{ padding: 0, wordBreak: 'break-all' }}>
														<span style={{ color: '#f54749' , textAlign: 'left' }}>
															<i className="bi bi-telephone"></i> Telefone
														</span>
														<div>{Data.phone}</div>
													</span>
													<span className="d-flex flex-column" style={{ padding: 0, wordBreak: 'break-all' ,  alignItems: 'flex-start' }}>
														<span style={{ color: '#f54749' , textAlign: 'left'}}>
															<i className="fa-solid fa-location-dot text-primary"></i> Endereço
														</span>
														<span style={{ textAlign: 'left'}}>
															{
																Data.addresses[0].street +
																' ( ' + Data.addresses[0].zipCode + ' ) '
															}
															{Data.addresses[0].number ? ', Nº ' + Data.addresses[0].number : 'S/N'}
															{
																'' +
																' , ' +
																Data.addresses[0].city +
																' - ' + Data.addresses[0].state
															}
														</span>
													</span>
												</span>
												<a href={`/loja?p=${Data.id}`} className="btn btn-primary py-1 px-3 mt-2">
													ver loja
												</a>
											</span>
										</div>
									</div>
								</div>
							</SwiperSlide>
						)

					})
					//: ''}
				}
				<div className="swiper-pagination"></div>
			</Swiper>
		</>
	)
}
export default SwiperCTN;